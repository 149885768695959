/* Portfolio container */
.portfolio {
	max-width: 800px;
	margin: 0 auto;
	padding: 50px 20px;
}

/* Header */
.portfolio header {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
	height: 350px;
}

.portfolio header h1 {
	font-size: 36px;
	margin: 0;
	line-height: 1.2;
}

.portfolio header p {
	font-size: 20px;
	color: #888;
	margin: 0;
	margin-top: 10px;
}

/* About me section */
.about-me {
	margin-bottom: 50px;
}

.about-me a {
	text-decoration: none;
	color: #000000;
}

.about-me h2 {
	font-size: 24px;
	margin-bottom: 10px;
}

.about-me li {
	font-size: 16px;
	line-height: 1.25;
	margin-top: 16px;
	list-style-type: none;
}

/* Skills section */
.skills {
	margin-bottom: 50px;
}

.skills h2 {
	font-size: 30px;
}
.skills h3 {
	font-size: 24px;
	margin-bottom: 2px;
	margin-top: 30px;
}

.skills ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.skills a {
	color: #000000;
	text-decoration: none;
	display: flex;
	align-items: center;
	flex-direction: column;

	min-width: 64px;
	padding: 5px;
	animation: bloopin 1s linear;
}

.skills img {
	height: 44px;
	width: 44px;
	margin-right: 6px;
	margin-left: 6px;
	mix-blend-mode: multiply;
}

.skills li {
	text-align: center;
	max-width: 150px;
}

.skills a:hover {
	scale: 1.2;
}

.skills a:active {
	scale: 1;
	filter: none;
}

/* Projects section */
.projects {
	margin-bottom: 50px;
}

.projects h2 {
	font-size: 30px;
	margin-bottom: 20px;
}

.project {
	margin-bottom: 30px;
}

.project h3 {
	font-size: 24px;
	margin-bottom: 0px;
}

.project p {
	font-size: 16px;
	line-height: 1.5;
	margin-top: 0px;
}

.project a {
	display: inline-block;
	font-size: 16px;
	margin-top: 10px;
	padding: 10px 20px;
	background-color: #333;
	color: #fff;
	text-decoration: none;
	border-radius: 20px;
}

/* Contact section */
.contact h2 {
	font-size: 24px;
	margin-bottom: 20px;
}

.contact li {
	font-size: 24px;
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-top: 10px;
	margin-bottom: 10px;
}
.contact li img {
	margin-left: 7px;
	height: 34px;
}

.contact li img:hover {
	margin-left: 7px;
	height: 34px;
	transform: scale(1.05);
}

.contact button {
	margin-left: 7px;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	font-weight: bold;
}

.contact button:hover {
	transform: scale(1.01);
}

/* NavBar section  */

.navBar {
	position: fixed;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80vh;
	background-color: #333;
	border-radius: 16px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	transition: all 0.3s ease;
	overflow: hidden;
	z-index: 10;
}

.navBar:hover {
	width: 200px;
}

.navBar li {
	text-decoration: none;
	position: relative;
	list-style: none;
	margin-bottom: 20px;
	margin-right: 90px;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	cursor: pointer;
	white-space: nowrap;
	width: 30px;
	text-decoration: none;
}

.navBar li::before {
	content: "";
	position: absolute;
	top: 0;
	left: -20px;
	width: 10px;
	height: 100%;
	background-color: #fff;
	border-radius: 10px;
	opacity: 0;
	transition: all 0.3s ease;
}

.navBar li:hover::before {
	opacity: 1;
}

.navBar li.active::before {
	opacity: 1;
}

.navBar a:active {
	text-decoration: none;
}

.navBar li:hover {
	transform: scale(1.2);
}

@media only screen and (max-width: 1200px) {
	.navBar {
		position: sticky;
		left: 0;
		top: 0%;
		transform: translateY(-50%);
		display: flex;
		flex-direction: row;
		flex-shrink: initial;
		justify-content: space-around;
		align-items: center;
		width: 90%;
		height: 100px;
		background-color: #333;
		border-radius: 40px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		transition: all 0.3s ease;
	}

	.navBar:hover {
		width: 90%;
		text-decoration: underline; /* Optional underline on hover */
		transform: scale(1.05);
	}

	.navBar li {
		margin-top: 24px;
		margin-left: 0px;
		text-decoration: none !important; /* Ensure no underline */
	}

	.navBar a {
		margin-left: 0px;
		text-decoration: none !important; /* Ensure no underline */
		color: inherit;
		transition: all 0.3s ease;
	}
}

@media only screen and (max-width: 800px) {
	.navBar {
		/* position: sticky; */
		left: 0px;
		top: 0px;
		transform: translateY(0%);
		display: flex;
		flex-direction: column;
		flex-shrink: initial;
		justify-content: center;
		align-items: center;
		width: 90%;
		height: fit-content;
		background-color: #333;
		border-radius: 0px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		transition: all 0.3s ease;
		border-radius: 0px;
	}

	.navBar:hover {
		width: 90%;
	}

	.navBar li {
		margin: 10px 0px;
		width: 35%;
		white-space: none;
	}

	.navBar a {
		margin: 10px 0px;
		width: 35%;
		white-space: none;
	}

	.about-me {
		margin-top: 120px;
		margin-bottom: 50px;
	}
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 16/9) and (max-width: 1200px) {
	/* CSS rules for landscape orientation with an aspect ratio of 16:9 or greater */
	.navBar {
		position: sticky;
		left: 0;
		top: 0%;
		transform: translateY(-50%);
		display: flex;
		flex-direction: row;
		flex-shrink: initial;
		justify-content: space-around;
		align-items: center;
		width: 90%;
		height: 100px;
		background-color: #333;
		border-radius: 40px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		transition: all 0.3s ease;
	}

	.navBar:hover {
		width: 90%;
	}

	.navBar li {
		margin-top: 24px;
		margin-left: 0px;
	}

	.navBar a {
		margin-left: 0px;
	}
}

#fade-out {
	opacity: 0;
	transition: opacity 0s ease-out;
}

#fade-in {
	opacity: 1;
	transition: opacity 0.2s ease-in;
}

.image-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 300px;
	height: 300px;
}

.image-container img {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-container .glow {
	border-radius: 50%;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
	animation: glowing 2s ease-in-out infinite;
}

.snoof {
	width: 70%;
}

@keyframes glowing {
	0% {
		transform: scale(1);
		filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
	}
	50% {
		transform: scale(1.1);
		filter: drop-shadow(14px 14px 4px rgba(0, 0, 0, 0.5));
	}
	100% {
		transform: scale(1);
		filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
	}
}

@keyframes bloopin {
	0% {
		transform: scale(0.1);
		opacity: 0.4;
	}
	50% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
